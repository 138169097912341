import Home from "../../views/student/Home.vue";

const studentRoutes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "Home",
        },
        component: () => import("../../views/student/Dashboard.vue"),
      },
      {
        path: "/myProfile",
        name: "myProfile",
        meta: {
          title: "My Profile",
        },
        component: () => import("../../views/student/StudentProfile.vue"),
      },
      {
        path: "/changePassword",
        name: "changePassword",
        meta: {
          title: "Change your password",
        },
        component: () => import("../../views/student/ChangePassword.vue"),
      },
      {
        path: "/myTests",
        name: "myTests",
        meta: {
          title: "My Tests",
        },
        component: () => import("../../views/student/TestReportList.vue"),
      },
      {
        path: "/weeklyReports",
        name: "weeklyReports",
        meta: {
          title: "Weekly Reports",
        },
        component: () => import("../../views/student/WeeklyReportList.vue"),
      },
      {
        path: "/myClasses",
        name: "classRoomList",
        meta: {
          title: "My Classes",
        },
        component: () => import("../../views/student/ClassRoomList.vue"),
      },
      {
        path: "/myPointsRecords",
        name: "myPointsRecords",
        meta: {
          title: "Points Issued List",
        },
        component: () => import("../../views/student/PointsRecordList.vue"),
      },
      {
        path: "/myReceipts",
        name: "myReceipts",
        meta: {
          title: "My Receipts",
        },
        component: () => import("../../views/student/ReceiptList.vue"),
      },
      {
        path: "/receiptDetail",
        name: "receiptDetail",
        meta: {
          title: "Receipt detail",
        },
        component: () => import("../../views/common/ReceiptTemplate.vue"),
      },
      {
        path: "/404",
        name: "404",
        meta: {
          title: "Not Found",
        },
        component: () => import("../../views/common/404.vue"),
      },
      {
        path: "/403",
        name: "403",
        meta: {
          title: "Unauthenticated",
        },
        component: () => import("../../views/common/403.vue"),
      },
    ],
  },
  {
    path: "/paymentReturn",
    name: "paymentReturn",
    meta: {
      title: "Payment completed",
    },
    component: () => import("../../views/common/PaymentReturnView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Sign In",
    },
    component: () => import("../../views/student/Login.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    meta: {
      title: "Forget your password",
    },
    component: () => import("../../views/common/ForgetPassword.vue"),
  },
];

export default studentRoutes;
