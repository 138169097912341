<template>
  <el-table
    :data="pointsTable"
    border
    ref="multipleTable"
    header-cell-class-name="table-header"
  >
    <el-table-column prop="type" label="Type">
      <template #default="">
        <el-tag effect="dark">{{ "Points" }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="points" label="Points">
      <template #default="scope">
        <span v-html="'Points ' + scope.row.points"></span>
      </template>
    </el-table-column>
    <el-table-column prop="points" label="Bundle Discount">
      <template #default="scope">
        <span v-html="this.$formatter.formatDecimal(scope.row.discount * 100)"></span>%
      </template>
    </el-table-column>
    <el-table-column prop="unitPrice" label="Per Unit Price">
      <template #default="scope">
        <span v-html="'$' + this.calculatePerUnitPrice(scope.row)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="Select Quantity">
      <template #default="scope">
        <el-select v-model="scope.row.quantity">
          <el-option
            v-for="item in options.quantityList"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="totalAmount" label="Item Cost">
      <template #default="scope">
        <span
          :style="scope.row.quantity > 0 ? 'color:red;' : ''"
          v-html="'$' + this.calculateItemTotalAmount(scope.row)"
        ></span>
      </template>
    </el-table-column>
  </el-table>
  <div class="buttonPanel">
    <el-button type="primary" @click="handleBuyPoints"> Buy </el-button>
  </div>
</template>
<script>
import { getData, postData } from "../../service/api/index";
import { ElMessageBox } from "element-plus";

export default {
  props: {
    paymentLink: String,
  },
  data() {
    return {
      controllerUrl: "paymate",
      options: {
        quantityList: [],
      },
      cashToPointsRate: 0.0001,
      quantityThreshold: 10,
      pointsTable: [],
      pointsTableForClear: [],
    };
  },
  created() {
    window.addEventListener("message", this.receiveMessage);
    this.pointsTable = JSON.parse(
      JSON.stringify(this.$appSetting.globalConfig.topUpPointsTemplateList)
    );
    console.log("created", this.pointsTable);

    for (let i = 0; i < this.pointsTable.length; i++) {
      this.pointsTable[i].quantity = 0;
    }

    for (let i = 0; i <= this.quantityThreshold; i++) {
      this.options.quantityList.push(i);
    }

    this.pointsTableForClear = JSON.parse(JSON.stringify(this.pointsTable));
  },
  unmounted() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    async handleBuyPoints() {
      let res = null;
      let totalAmount = 0;
      let totalPoints = 0;
      let productList = [];
      this.pointsTable.forEach((e) => {
        if (e.quantity) {
          totalAmount += this.calculateItemTotalAmount(e);
          totalPoints += e.quantity * e.points;
          productList.push({ name: e.name, quantity: e.quantity });
        }
      });
      totalAmount = Math.round(totalAmount * 100) / 100;

      if (!totalAmount) {
        this.$message.warning("Please select at least one quantity from above list.");
        return;
      }
      let getTokenQuery = {
        productList: JSON.stringify(productList),
        points: totalPoints,
        totalAmount: totalAmount,
        returnUrl: "https://student.jeeducation.com.au/paymentReturn",
        model: 1,
      };
      res = await getData("/paymate/raiseTransactionForTopUpPoints", getTokenQuery);

      console.log("topUpPoints", res);
      if (res.result && res.code === "200") {
        let result = res.result;
        let url = `${result.paymentURL}${result.token}`;
        let transactionId = result.transactionId;
        // window.open(url, "_blank");
        let showWidth = 600;
        let showHeight = 780;
        this.$msgbox({
          title: " ",
          // message: `<iframe style="left:0;top:0;z-index:1;border-width:0" width="${showWidth}px" height="${showHeight}px" src="http://localhost:8081/paymentReturn?r=BZUoYg_Gp0ee_kNdILyLqw&s=990Sk-Kwc0WIOLYfIMtSBA&p=paymate&m=1&u=cancel_url"></iframe>`,
          message: `<iframe id='topUpPointsIFrame' style="left:0;top:0;z-index:1;border-width:0" width="${showWidth}px" height="${showHeight}px" src="${url}"></iframe>`,
          closeOnClickModal: false,
          showConfirmButton: false,
          showCancelButton: false,
          cancelButtonText: "Close",
          dangerouslyUseHTMLString: true,
          center: true,
          draggable: true,
          customStyle: `width:${showWidth + 54}px;`,
          beforeClose: async (action, instance, done) => {
            if (action === "close" || action === "cancel") {
              // check if the payment is finished
              let checkPaymentQuery = {
                transactionId: transactionId,
                studentId: this.$user.getUsername(),
                platform: "Paymate",
              };
              let retForCheckingStatus = await postData(
                "/paymate/checkStatus",
                checkPaymentQuery
              );
              if (retForCheckingStatus.result !== 2048) {
                this.$confirm(
                  "Are you sure to give up the payment? <br>Due to we haven't received confirmation message from bank, if you have completed the payment, please screenshot approved information and send to JEEducation administrator, then we could manually approve your payment.",
                  "Prompt",
                  {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                  }
                )
                  .then(() => {
                    done();
                  })
                  .catch(() => {});
              } else {
                this.$user.refreshPoints();
                this.$message.success("Top up successfully!");
                this.$store.commit("handleShowTopUpDialog", false);
                this.pointsTable = JSON.parse(JSON.stringify(this.pointsTableForClear));
                done();
              }
            } else {
              return;
            }
          },
        });
      } else {
        this.$message.error("Raising a payment failed, error message: " + res.message);
      }
    },
    receiveMessage(event) {
      let data = event.data;
      if (
        data.type === "TOP_UP_POINTS_PAYMENT_DIALOG_EVENT" &&
        data.message === "Close"
      ) {
        this.closePaymentDialog();
      }
    },
    closePaymentDialog() {
      ElMessageBox.close();
    },
    cancelPayment(transactionId) {
      let query = {};
      query.transactionId = transactionId;
      query.studentId = this.$user.getUsername();
      query.platform = "Paymate";
      console.log("cancelPayment", query);

      postData(`${this.controllerUrl}/cancel`, query).then(() => {
        this.$message.success("Cancel payment successfully!");
      });
    },
    calculatePerUnitPrice(itemData) {
      let ratioForCashToPoints = this.$appSetting.getGenericValue(
        // 10 points for $1
        "CASH_TO_POINTS_RATIO",
        10
      );
      return itemData.points
        ? this.$formatter.formatDecimal(
            (1 / ratioForCashToPoints) * (1 - itemData.discount),
            3
          )
        : null;
    },
    calculateItemTotalAmount(itemData) {
      let ratioForCashToPoints = this.$appSetting.getGenericValue(
        // 10 points for $1
        "CASH_TO_POINTS_RATIO",
        10
      );
      return (
        itemData.quantity *
        (itemData.points / ratioForCashToPoints) *
        (1 - itemData.discount)
      );
    },
  },
};
</script>

<style scoped>
.buttonPanel {
  margin-top: 20px;
  text-align: right;
  padding-right: 10px;
}
</style>
