<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#233c64"
      text-color="#bfcbd9" active-text-color="#ffa500" unique-opened router>
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i class="el-icon">
                <component v-bind:is="item.icon"></component>
              </i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{
      threeItem.title
    }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{
      subItem.title
    }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i class="el-icon">
              <component v-bind:is="item.icon"></component>
            </i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "home-filled",
          index: "dashboard",
          title: "JE Tree",
        },
        {
          icon: "house",
          index: "myClasses",
          title: "Enrolled Classes",
        },
        {
          icon: "dataLine",
          index: "weeklyReports",
          title: "Weekly Reports",
        },
        {
          icon: "histogram",
          index: "myTests",
          title: "Term Test Reports",
        },
        {
          icon: "coin",
          index: "myPointsRecords",
          title: "Points Records",
        },
        {
          icon: "money",
          index: "myReceipts",
          title: "Receipts",
        },
        {
          icon: "user-filled",
          index: "myProfile",
          title: "Profile",
        },
        {
          icon: "key",
          index: "changePassword",
          title: "Change Password",
        },
      ],
    };
  },
  created() {
    this.$emitter.on("WINDOW_SIZE_CHANGE", (arg) => {
      console.log("Sidebar.received event:", "WINDOW_SIZE_CHANGE", arg);
      this.resizeWindowHandler(arg[0], arg[1], arg[2]);
    });
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  methods: {
    resizeWindowHandler(inMiniScreen, windowWidth) {
      if (windowWidth < 760) {
        this.collapseChange(true);
      } else {
        this.collapseChange(false);
      }
    },
    collapseChange(collapse) {
      this.$store.commit("handleCollapse", collapse);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .promotion {
    margin-right: 50px;
  }
}

.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 180px;
}

.sidebar>ul {
  height: 100%;
}
</style>
